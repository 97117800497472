<template>
  <div class="ptm-page flex-col">
    <div class="ptm-main1 flex-col">
      <div class="ptm-mod1 flex-col">
        <div class="ptm-section1 flex-col">
          <headInfo :type="type"></headInfo>
          <span class="ptm-word2">智慧物流&nbsp;&nbsp;&nbsp;全新变革</span>
          <span class="ptm-word3">以创新为美&nbsp;为价值而生</span>
          <!-- <div class="ptm-layer2 flex-col" @click="toNewPage(5)">
            <span class="ptm-word4">立即试用</span>
          </div> -->
        </div>
        <div class="ptm-section2 flex-col">
          <div class="ptm-layer3 flex-col"></div>
        </div>
      </div>
      <span class="ptm-txt5">产品介绍</span>
      <span class="ptm-info2"
        >运用先进算法，解决企业运输线路、多订单追踪管理、云端智能调度、账单管理、可视化运输过程等场景中的任何问题，减少企业压力，增加行业竞争力</span
      >
      <div class="ptm-mod2 flex-row">
        <img
          class="ptm-img17"
          referrerpolicy="no-referrer"
          src="@/assets/images/procedure/tmsone.png"
        />
        <div class="ptm-bd18 flex-col"></div>
        <div class="ptm-bd19 flex-col"></div>
        <img
          class="ptm-pic24"
          referrerpolicy="no-referrer"
          src="@/assets/images/procedure/tmsfour.png"
        />
      </div>
      <div class="ptm-mod5 flex-row">
        <span class="ptm-info3">精准定位</span>
        <span class="ptm-word5">实时更新</span>
        <span class="ptm-info4">物流跟踪</span>
        <span class="ptm-info5">轨迹</span>
      </div>
      <div class="ptm-mod6 flex-row justify-between">
        <span class="ptm-txt6">采用先进GPS+RFID，运输工具和货品全量定位</span>
        <span class="ptm-info6">后台可定义信息更新时间，实时提醒用户</span>
        <span class="ptm-txt7">可视化物流跟踪，每一节点清晰明了，及时报警</span>
        <span class="ptm-word6"
          >所有运输轨迹一键查看，跟踪定位，最大程度降低差错率</span
        >
      </div>
      <div class="ptm-mod7 flex-col">
        <div class="ptm-section3 flex-col">
          <span class="ptm-word7">产品亮点</span>
          <span class="ptm-word8"
            >灵活丰富的集成接口，可无缝快速对接多种系统</span
          >
          <div class="ptm-main2 flex-col"></div>
          <div class="ptm-main3 flex-row justify-between">
            <div class="ptm-mod8 flex-col">
              <div class="ptm-block2 flex-col">
                <div class="ptm-group1 flex-col">
                  <span class="ptm-info7">GPS定位+RFID</span>
                  <span class="ptm-paragraph1"
                    >实时定位运输工具位置<br />实时监控货品运输状况</span
                  >
                  <img
                    class="ptm-pic3"
                    referrerpolicy="no-referrer"
                    src="@/assets/main/productright.png"
                  />
                </div>
              </div>
              <img
                class="ptm-img2"
                referrerpolicy="no-referrer"
                src="@/assets/images/procedure/tmshighone.png"
              />
            </div>
            <div class="ptm-mod9 flex-col">
              <div class="ptm-section4 flex-col">
                <div class="ptm-block3 flex-col">
                  <span class="ptm-word9">轨迹监控</span>
                  <span class="ptm-paragraph2"
                    >智能轨迹监控，实时更新定位<br />可视化轨迹，全面掌握动态</span
                  >
                  <img
                    class="ptm-pic4"
                    referrerpolicy="no-referrer"
                    src="@/assets/main/productright.png"
                  />
                </div>
              </div>
              <img
                class="ptm-pic5"
                referrerpolicy="no-referrer"
                src="@/assets/images/procedure/tmshightwo.png"
              />
            </div>
          </div>
        </div>
        <div class="ptm-section5 flex-col">
          <div class="ptm-mod10 flex-col">
            <div class="ptm-wrap1 flex-col">
              <span class="ptm-word10">云服务更轻量</span>
              <span class="ptm-paragraph3"
                >产品架构拓展性高<br />产品部署灵活性高<br />产品服务安全性高</span
              >
              <img
                class="ptm-img3"
                referrerpolicy="no-referrer"
                src="@/assets/main/productright.png"
              />
            </div>
          </div>
          <img
            class="ptm-img4"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/tmshighthird.png"
          />
        </div>
      </div>
      <span class="ptm-word11">多种业务&nbsp;&nbsp;&nbsp;全面把控</span>
      <span class="ptm-word12"
        >让您的销售、营销和服务支持工作自动化，更好地服务更多的客户</span
      >
      <div class="ptm-mod11 flex-row justify-between">
        <div class="ptm-mod12 flex-col">
          <div class="ptm-outer1 flex-col">
            <span class="ptm-word13">三方物流</span>
            <div class="ptm-section6 flex-row justify-between">
              <div class="ptm-section13 flex-col"></div>
              <span class="ptm-word14">功能专业化</span>
            </div>
            <div class="ptm-section7 flex-row justify-between">
              <div class="ptm-bd20 flex-col"></div>
              <span class="ptm-word15">管理系统化</span>
            </div>
            <div class="ptm-section8 flex-row justify-between">
              <div class="ptm-layer12 flex-col"></div>
              <span class="ptm-txt8">信息网络化</span>
            </div>
          </div>
          <div class="ptm-mod13 flex-col"></div>
        </div>
        <!-- <div  class="ptm-mod13 flex-col"></div> -->
        <div class="ptm-mod14 flex-col">
          <div class="ptm-box3 flex-row">
            <span class="ptm-txt9">定制物流</span>
          </div>
          <div class="ptm-box4 flex-row justify-between">
            <div class="ptm-box24 flex-col"></div>
            <span class="ptm-info8">服务个性化</span>
          </div>
          <div class="ptm-box5 flex-row justify-between">
            <div class="ptm-outer12 flex-col"></div>
            <span class="ptm-txt10">需求多样化</span>
          </div>
          <div class="ptm-box6 flex-row justify-between">
            <div class="ptm-block15 flex-col"></div>
            <span class="ptm-infoBox1"
              >API无缝对接财务系统<br />业务与财务流程全面打通</span
            >
          </div>
          <div class="ptm-mod13 flex-col"></div>
        </div>
      </div>
      <div class="ptm-mod15 flex-row">
        <div class="ptm-main4 flex-col">
          <div class="ptm-bd1 flex-row">
            <span class="ptm-word16">核心功能</span>
          </div>
          <div class="ptm-bd2 flex-row">
            <div class="ptm-group2 flex-col"></div>
          </div>
          <div class="ptm-bd3 flex-row justify-between">
            <img
              class="ptm-pic25"
              referrerpolicy="no-referrer"
              src="@/assets/images/procedure/tmscoreone.png"
            />
            <img
              class="ptm-img18"
              referrerpolicy="no-referrer"
              src="@/assets/images/procedure/tmscoretwo.png"
            />
            <img
              class="ptm-img19"
              referrerpolicy="no-referrer"
              src="@/assets/images/procedure/tmscorethird.png"
            />
            <img
              class="ptm-img20"
              referrerpolicy="no-referrer"
              src="@/assets/images/procedure/tmscorefour.png"
            />
            <img
              class="ptm-img21"
              referrerpolicy="no-referrer"
              src="@/assets/images/procedure/tmscorefive.png"
            />
          </div>
          <div class="ptm-bd4 flex-row justify-between">
            <span class="ptm-info9">订单追踪</span>
            <span class="ptm-info10">调度分配</span>
            <span class="ptm-word17">路线规划</span>
            <span class="ptm-info11">运输管理</span>
            <span class="ptm-word18">价格管理</span>
          </div>
          <div class="ptm-bd5 flex-row justify-between">
            <span class="ptm-word19">追踪每一条订单，实时更新订单进度</span>
            <span class="ptm-txt11">支持自动调度分配，无需人工管理</span>
            <span class="ptm-word20"
              >支持多种运输路线规划，让运输不再有压力</span
            >
            <span class="ptm-word21">高效、合理分配资源并处理管控运输任务</span>
            <span class="ptm-txt12">运输团队招投标管理，自动识别最低价</span>
          </div>
          <div class="ptm-bd6 flex-row justify-between">
            <img
              class="ptm-img22"
              referrerpolicy="no-referrer"
              src="@/assets/images/procedure/tmscoresix.png"
            />
            <img
              class="ptm-img23"
              referrerpolicy="no-referrer"
              src="@/assets/images/procedure/tmscoreseven.png"
            />
            <img
              class="ptm-pic26"
              referrerpolicy="no-referrer"
              src="@/assets/images/procedure/tmscoreeight.png"
            />
            <img
              class="ptm-img24"
              referrerpolicy="no-referrer"
              src="@/assets/images/procedure/tmscorenine.png"
            />
            <img
              class="ptm-pic27"
              referrerpolicy="no-referrer"
              src="@/assets/images/procedure/tmscoreten.png"
            />
          </div>
          <div class="ptm-bd7 flex-row justify-between">
            <span class="ptm-word22">异常管理</span>
            <span class="ptm-txt13">实时更新</span>
            <span class="ptm-word23">多方协同</span>
            <span class="ptm-txt14">数据报表</span>
            <span class="ptm-info12">系统维护</span>
          </div>
          <div class="ptm-bd8 flex-row justify-between">
            <span class="ptm-word24"
              >针对各种异常作业情况，提出有效应对方案</span
            >
            <span class="ptm-word25"
              >招投标、运输过程、订单追踪等实时反馈信息</span
            >
            <span class="ptm-word26">无需担心操作，每一环节全流程协同</span>
            <span class="ptm-word27">可生成各类报表，助力业务人员所有场景</span>
            <span class="ptm-txt15">免费一年后期系统维护，无后顾之忧</span>
          </div>
        </div>
      </div>
      <span class="ptm-info16">产品流程</span>
      <span class="ptm-txt16">灵活丰富的集成接口，可无缝快速对接多种系统</span>
      <div class="ptm-mod16 flex-col">
        <div class="ptm-section9 flex-row justify-between">
          <!-- <div class="ptm-outer4 flex-col">
            <div class="ptm-mod17 flex-col">
              <div class="ptm-block4 flex-col">
                <div class="ptm-mod18 flex-col">
                  <span class="ptm-paragraph4">ERP/OMS<br />订单管理系统</span>
                </div>
                <div class="ptm-mod19 flex-col"></div>
                <div class="ptm-mod20 flex-row justify-between">
                  <div class="ptm-bd9 flex-col">
                    <span class="ptm-infoBox2"
                      >WCS<br />设备控制<br />系统</span
                    >
                  </div>
                  <div class="ptm-bd10 flex-col">
                    <span class="ptm-paragraph5"
                      >WMS<br />仓储管理<br />系统</span
                    >
                  </div>
                </div>
                <div class="ptm-mod21 flex-col"></div>
                <div class="ptm-mod22 flex-col">
                  <span class="ptm-txt17">开放平台标准接口</span>
                </div>
              </div>
              <div class="ptm-block5 flex-col"></div>
            </div>
          </div>
          <div class="ptm-outer5 flex-col">
            <div class="ptm-block6 flex-col justify-between">
              <div class="ptm-bd11 flex-col">
                <div class="ptm-outer6 flex-row">
                  <div class="ptm-box7 flex-col">
                    <span class="ptm-info17">国内物流</span>
                  </div>
                  <div class="ptm-box8 flex-col justify-between">
                    <img
                      class="ptm-icon33"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnge6f182fbbbf2a9058da1e3f8b93c8cd1b32077a37d723a94d0198e2a4e0a8ddd"
                    />
                    <span class="ptm-word30">网络订单</span>
                  </div>
                  <img
                    class="ptm-label2"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng3371eb26f3ee42b018bddb3590d15942ab4bda39925ba53661606e947b40ed87"
                  />
                  <div class="ptm-box9 flex-col">
                    <img
                      class="ptm-label42"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngff8477f8f195843327cebb16fbedc2fd226482c5ec3f2436274593765f1bc085"
                    />
                    <span class="ptm-info18">新增运单</span>
                    <img
                      class="ptm-label4"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf4d657fdf388e14a56a3c98f01921c29235e54396f930e32910b6083c518c9f2"
                    />
                  </div>
                  <img
                    class="ptm-icon1"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng3371eb26f3ee42b018bddb3590d15942ab4bda39925ba53661606e947b40ed87"
                  />
                  <div class="ptm-box10 flex-col">
                    <img
                      class="ptm-icon34"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngc4df2d8b89ca67991eb909eded6eb5fef8504af3bacc55a3cb6beaaaa243424f"
                    />
                    <span class="ptm-info19">发货库存</span>
                    <img
                      class="ptm-label6"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf4d657fdf388e14a56a3c98f01921c29235e54396f930e32910b6083c518c9f2"
                    />
                  </div>
                  <img
                    class="ptm-icon2"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng3371eb26f3ee42b018bddb3590d15942ab4bda39925ba53661606e947b40ed87"
                  />
                  <div class="ptm-box11 flex-col">
                    <img
                      class="ptm-icon35"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng3f6bf06f103c6a6b786596d07a3d7082a9a391c6fe3df997184a1bc94198f1b5"
                    />
                    <span class="ptm-info20">发车管理</span>
                    <img
                      class="ptm-label7"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf4d657fdf388e14a56a3c98f01921c29235e54396f930e32910b6083c518c9f2"
                    />
                  </div>
                  <img
                    class="ptm-label8"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng3371eb26f3ee42b018bddb3590d15942ab4bda39925ba53661606e947b40ed87"
                  />
                  <div class="ptm-box12 flex-col justify-between">
                    <img
                      class="ptm-icon36"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng7bb76a51ef3ab2c06a1ba199092b14e8a874f2a3f754c0a43b41756c5e0d42d1"
                    />
                    <span class="ptm-info21">在途监控</span>
                  </div>
                  <img
                    class="ptm-icon5"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng3371eb26f3ee42b018bddb3590d15942ab4bda39925ba53661606e947b40ed87"
                  />
                  <div class="ptm-box13 flex-col">
                    <img
                      class="ptm-label43"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng965ddf10ceb30f639495a8cf96dabb6a7259b484c6700466da49eb01f6a1d600"
                    />
                    <span class="ptm-txt18">到车管理</span>
                    <img
                      class="ptm-label9"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf4d657fdf388e14a56a3c98f01921c29235e54396f930e32910b6083c518c9f2"
                    />
                  </div>
                  <img
                    class="ptm-icon7"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng3371eb26f3ee42b018bddb3590d15942ab4bda39925ba53661606e947b40ed87"
                  />
                  <div class="ptm-box14 flex-col justify-between">
                    <img
                      class="ptm-icon37"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngfeed3b84d0b574f6d129ef8aafe3300072a08b8ab4da369daa705f12638d8ff3"
                    />
                    <span class="ptm-word31">到站库存</span>
                  </div>
                  <img
                    class="ptm-icon9"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng3371eb26f3ee42b018bddb3590d15942ab4bda39925ba53661606e947b40ed87"
                  />
                  <div class="ptm-box15 flex-col justify-between">
                    <img
                      class="ptm-icon38"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf150d429caddaedfda7b740be903bc7d7c31cc25e848c674547d37f6be10be7b"
                    />
                    <span class="ptm-info22">送货管理</span>
                  </div>
                  <img
                    class="ptm-label11"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng3371eb26f3ee42b018bddb3590d15942ab4bda39925ba53661606e947b40ed87"
                  />
                  <div class="ptm-box16 flex-col justify-between">
                    <img
                      class="ptm-label44"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngc0ae676d19380bb8d83f34766db385d6236ab57922940574f1989c8dea8ff3b5"
                    />
                    <span class="ptm-txt19">签收管理</span>
                  </div>
                  <img
                    class="ptm-icon10"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng3371eb26f3ee42b018bddb3590d15942ab4bda39925ba53661606e947b40ed87"
                  />
                  <div class="ptm-box17 flex-col justify-between">
                    <img
                      class="ptm-label45"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng518ff68811267f182aa484b52847c70adc02852115a9e3bf3f15fb4bebe319ac"
                    />
                    <span class="ptm-word32">回单</span>
                  </div>
                </div>
                <div class="ptm-outer7 flex-row">
                  <img
                    class="ptm-label46"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng77044ad66e43c2abaa02fa77c703834b325a06a98e068c8ac2a92449572e4452"
                  />
                  <img
                    class="ptm-icon39"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnge979d5c2a40a69b604a00b6adc36a1285ab9da2c3be0cc6bc3faff65f38e9ac2"
                  />
                  <img
                    class="ptm-icon40"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngcc72c7a58e78d2b253cb78bb020066c40d0b8e33b92e5ca4c30589e70ed57dc7"
                  />
                  <img
                    class="ptm-icon41"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnge979d5c2a40a69b604a00b6adc36a1285ab9da2c3be0cc6bc3faff65f38e9ac2"
                  />
                </div>
                <div class="ptm-outer8 flex-row">
                  <span class="ptm-word33">提货管理</span>
                  <span class="ptm-txt20">中转外包</span>
                  <span class="ptm-word34">新增配载</span>
                  <span class="ptm-info23">中转外包</span>
                </div>
              </div>
              <div class="ptm-bd12 flex-col">
                <div class="ptm-outer9 flex-row">
                  <div class="ptm-main5 flex-col">
                    <span class="ptm-word35">跨境物流</span>
                  </div>
                  <div class="ptm-main6 flex-col justify-between">
                    <img
                      class="ptm-label47"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng075cc84939336312b5db0655e525ce2104f9551bd09cab360f6e039a01293f7c"
                    />
                    <span class="ptm-word36">中国卖家</span>
                  </div>
                  <img
                    class="ptm-label17"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngc0961e1589770a954224820d70e7de80fce2b716bc4db370c21810049302a01a"
                  />
                  <div class="ptm-main7 flex-col justify-between">
                    <img
                      class="ptm-label48"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4995880bcc7ceb5de95a0bfc2e440868e5efa9403aaa046327e9eaee729adc05"
                    />
                    <span class="ptm-word37">国内运输</span>
                  </div>
                  <img
                    class="ptm-label19"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngc0961e1589770a954224820d70e7de80fce2b716bc4db370c21810049302a01a"
                  />
                  <div class="ptm-main8 flex-col">
                    <img
                      class="ptm-label49"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng8b9330dc09b6ed3a5a2bdd7beaef2400f7b4fbbabec8dc68422a926c8a16cfa2"
                    />
                    <span class="ptm-word38">仓库中转</span>
                    <span class="ptm-txt21">(起运国)</span>
                  </div>
                  <img
                    class="ptm-icon13"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngc0961e1589770a954224820d70e7de80fce2b716bc4db370c21810049302a01a"
                  />
                  <div class="ptm-main9 flex-col justify-between">
                    <img
                      class="ptm-icon42"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng25e6083794dc4db0c8c4d7b63c552dafe6951a7a3cba8adcad69d80c45b33d2d"
                    />
                    <span class="ptm-word39">国出口报关</span>
                  </div>
                  <div class="ptm-main10 flex-col">
                    <div class="ptm-section10 flex-row justify-between">
                      <img
                        class="ptm-label21"
                        referrerpolicy="no-referrer"
                        src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngc0961e1589770a954224820d70e7de80fce2b716bc4db370c21810049302a01a"
                      />
                      <img
                        class="ptm-icon43"
                        referrerpolicy="no-referrer"
                        src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngefa4ab608487a3168cd1b17d791161bdda4332d07e46ea467262efe6508a2e73"
                      />
                      <img
                        class="ptm-icon16"
                        referrerpolicy="no-referrer"
                        src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngc0961e1589770a954224820d70e7de80fce2b716bc4db370c21810049302a01a"
                      />
                    </div>
                    <span class="ptm-word40">运输管理</span>
                    <span class="ptm-txt22">(火车/轮船/飞机)</span>
                  </div>
                  <div class="ptm-main11 flex-col">
                    <img
                      class="ptm-label50"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf00dddc8edeaa3db953f4b12e84943145ba8210b89ec1e5af0b52eb3ba0a11f2"
                    />
                    <span class="ptm-txt23">进口清关</span>
                    <span class="ptm-info24">(目的国)</span>
                  </div>
                  <img
                    class="ptm-label23"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngc0961e1589770a954224820d70e7de80fce2b716bc4db370c21810049302a01a"
                  />
                  <div class="ptm-main12 flex-col">
                    <img
                      class="ptm-icon44"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngaf8da224f5feccb3820eacea8b8ab7ea9378416dbfb7e94deaa1dd5adb763813"
                    />
                    <span class="ptm-word41">国内运输</span>
                    <span class="ptm-txt24">(目的国)</span>
                  </div>
                  <img
                    class="ptm-label24"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngc0961e1589770a954224820d70e7de80fce2b716bc4db370c21810049302a01a"
                  />
                  <div class="ptm-main13 flex-col justify-between">
                    <img
                      class="ptm-icon45"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng8b9330dc09b6ed3a5a2bdd7beaef2400f7b4fbbabec8dc68422a926c8a16cfa2"
                    />
                    <span class="ptm-info25">仓库中转</span>
                  </div>
                  <img
                    class="ptm-icon18"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngc0961e1589770a954224820d70e7de80fce2b716bc4db370c21810049302a01a"
                  />
                  <div class="ptm-main14 flex-col justify-between">
                    <img
                      class="ptm-label51"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngc498e688f16cdf3385017a56a1d89eedd46445aa2ebf28ba6f7e92d83c1383e2"
                    />
                    <span class="ptm-word42">配送至买家</span>
                  </div>
                  <img
                    class="ptm-label26"
                    referrerpolicy="no-referrer"
                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngc0961e1589770a954224820d70e7de80fce2b716bc4db370c21810049302a01a"
                  />
                  <div class="ptm-main15 flex-col justify-between">
                    <img
                      class="ptm-icon46"
                      referrerpolicy="no-referrer"
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng09e235e6cb2acf26bcc95cb22a82c3059aef5db1eb8a3126afd5261c936e4d2c"
                    />
                    <span class="ptm-word43">回单</span>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
        <!-- <img
          class="ptm-img7"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnga0bcb0675cf02461d54d08ba4542bdec16b468b5d728d3350f0d01352db752ce"
        /> -->
      </div>
      <span class="ptm-txt25">典型案例</span>
      <div v-show="isShow" class="ptm-mod23 flex-row justify-between">
        <div class="ptm-mod24 flex-col">
          <div class="ptm-block7 flex-col">
            <div class="ptm-mod25 flex-col"></div>
          </div>
          <img
            class="ptm-pic14"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/tmsinstwo.png"
          />
        </div>
        <div class="ptm-mod26 flex-col">
          <span class="ptm-word44">中船黄埔文冲物流-三方物流</span>
          <span class="ptm-info26">传统工业型公司，生产制造类别</span>
          <div class="ptm-layer7 flex-col"></div>
          <span class="ptm-info27">痛点：</span>
          <span class="ptm-infoBox3"
            >1.信息链不畅<br />2.承运商较多，承运商信息化水平不一，无法实现配送过程全程可视化<br />3.物流数据分散，不规范，数据挖掘分析能力有限，无法为相关管理决策提供有效的数据支撑</span
          >
          <span class="ptm-txt26">解决方案：</span>
          <span class="ptm-infoBox4"
            >1.配送过程关键节点作业跟踪，细化作业时效监控，及时反馈，实时记录<br />2.松云TMS、RF、司机APP、微信和WebWork接口平台应用打造仓储运输全程物流管理平台<br />3.多种报表覆盖各项功能，数据算法分析，通过订单生产看板实时了解订单的不同状态</span
          >
          <div class="ptm-layer8 flex-row">
            <span class="ptm-word45">50%</span>
            <span class="ptm-txt27">物流运输</span>
            <div class="ptm-wrap2 flex-col"></div>
            <span class="ptm-info28">80%</span>
            <span class="ptm-txt28">跟单效率</span>
            <div class="ptm-wrap3 flex-col"></div>
          </div>
          <div class="ptm-layer9 flex-row justify-between">
            <button
              class="ptm-box18 flex-col"
              @click="jumpPage('instance', 'header-ident')"
            >
              <span class="ptm-word46">查看详情</span>
            </button>
            <div class="ptm-icon20" @click="clickCut" />
          </div>
        </div>
      </div>
      <div v-if="!isShow" class="ptn-group17 flex-row justify-between">
        <div class="ptn-box6 flex-col">
          <div class="ptn-main17 flex-col">
            <div class="ptn-layer15 flex-col"></div>
          </div>
          <img
            class="ptn-pic12"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/tmsinsthird.png"
          />
        </div>
        <div class="ptn-box7 flex-col">
          <span class="ptn-txt28">南航物流</span>
          <span class="ptn-word46">航天行业领域NO.1，超大型航空公司</span>
          <div class="ptn-outer6 flex-col"></div>
          <span class="ptn-txt29">痛点：</span>
          <span class="ptn-infoBox5"
            >1.运输方式复杂：国内以及国外航空物流，车辆运输、快递<br />2.承运商多：合作承运商100多家，较难进行统一管理<br />3.业务复杂：涉及到的国外航空物流（进出口清关等）操作复杂、严谨、精准</span
          >
          <span class="ptn-txt30">解决方案：</span>
          <span class="ptn-paragraph4"
            >1.不同运输方式统一模板，信息准确、不易出错、便于管理<br />2.订单自动配载：TMS系统中对订单分段，可以选择多个订单合并配载；在指派规则中录入实际指派承运商的规则，在波次模板中建立波次，自动指派承运商<br />实时跟踪与查询：承运商和南航现场人员可以通过APP，实时得知运输执行的完成情况</span
          >
          <div class="ptn-outer7 flex-row">
            <span class="ptn-txt40">50%</span>
            <span class="ptn-info22">订单处理</span>
            <div class="ptn-box8 flex-col"></div>
            <span class="ptn-word67">30%</span>
            <span class="ptn-word47">容错率</span>
            <div class="ptn-box9 flex-col"></div>
          </div>
          <div class="ptn-outer8 flex-row justify-between">
            <button
              class="ptn-bd16 flex-col"
              @click="jumpPage('instance', 'header-ident')"
            >
              <span class="ptn-word48">查看详情</span>
            </button>
            <div class="ptn-label18" @click="clickCut" />
          </div>
        </div>
      </div>
      <secFoot />
      <footInfo />
    </div>
    <div class="ptm-main16 flex-col">
      <div class="ptm-section11 flex-col">
        <span class="ptm-word59">跨境物流</span>
        <div class="ptm-bd15 flex-row justify-between">
          <div class="ptm-wrap5 flex-col"></div>
          <span class="ptm-info40">服务多元化</span>
        </div>
        <div class="ptm-bd16 flex-row justify-between">
          <div class="ptm-main22 flex-col"></div>
          <span class="ptm-word60">形式多样化</span>
        </div>
        <div class="ptm-bd17 flex-row justify-between">
          <div class="ptm-bd21 flex-col"></div>
          <span class="ptm-word61">增值服务多</span>
        </div>
      </div>
      <div class="ptm-mod13 flex-col"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      type: 1,
      isShow: true,
      constants: {},
    };
  },
  methods: {
    jumpPage(path, id) {
      var path = path;
      var Id = id;
      localStorage.setItem("toId", Id);
      this.$router.push({
        name: path,
        params: {
          sel: "second",
        },
      });
    },
    // 跳转页面
    toNewPage(index) {
      let url = "";
      // index的类型 0-主页 1-产品介绍 2-服务体系 3-典型案例 4-关于松云 5-预约注册
      switch (index) {
        case 0: //主页
          url = "/";
          break;
        case 1: // 产品介绍
          url = "/product/erp";
          break;
        case 2: // 服务体系
          url = "/system";
          break;
        case 3: //  典型案例
          url = "/instance/erp";
          break;
        case 4: //关于松云
          url = "/about";
          break;
        case 5: //预约注册
          url = "/register";
          break;
      }
      if (url == "") {
        return;
      }
      this.$router.push({ path: url });
    },
    clickCut() {
      this.isShow = !this.isShow;
    },
  },
};
</script>
<style  rel="stylesheet/scss" lang="scss" >
.ptn-group17 {
  width: 1316px;
  height: 609px;
  margin: 49px 0 0 274px;
  .ptn-box6 {
    z-index: 733;
    height: 611px;
    // border: 1px solid rgba(151, 151, 151, 1);
    overflow: hidden;
    // background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngd092165dc94d7acb29aa52b4ccfb988c142d13390d1782d6eacab343bef37c88)
    //   0px 0px no-repeat;
    background-size: 591px 609px;
    width: 593px;
    position: relative;
    margin: -1px 0 0 -1px;
    .ptn-main17 {
      z-index: 736;
      position: relative;
      width: 516px;
      height: 545px;
      overflow: hidden;
      // background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng61f3a34d91b920ead21eaac6098e10fa297cc55eb6b6914658076e6e3ed3549f)
      //   100% no-repeat;
      margin: 36px 0 0 56px;
      .ptn-layer15 {
        z-index: 737;
        position: absolute;
        left: 1px;
        top: -19px;
        width: 516px;
        height: 689px;
        background: url(../../assets/images/procedure/tmsinsfour.png) 0px 19px
          no-repeat;
        background-size: 516px 545px;
      }
    }
    .ptn-pic12 {
      z-index: 734;
      position: absolute;
      left: -1px;
      top: -1px;
      width: 591px;
      height: 609px;
    }
  }
  .ptn-box7 {
    width: 645px;
    height: 545px;
    margin-top: 35px;
    .ptn-txt28 {
      width: 91px;
      height: 29px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(40, 40, 40, 1);
      font-size: 21px;
      letter-spacing: 2px;
      font-family: AlibabaPuHuiTiM;
      white-space: nowrap;
      line-height: 29px;
      text-align: left;
    }
    .ptn-word46 {
      width: 255px;
      height: 20px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(153, 153, 153, 1);
      font-size: 14px;
      letter-spacing: 1.75px;
      font-family: AlibabaPuHuiTiR;
      white-space: nowrap;
      line-height: 20px;
      text-align: left;
      margin-top: 6px;
    }
    .ptn-outer6 {
      width: 84px;
      height: 4px;
      background-color: rgba(37, 184, 47, 1);
      margin-top: 10px;
    }
    .ptn-txt29 {
      width: 54px;
      height: 30px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(40, 40, 40, 1);
      font-size: 16px;
      letter-spacing: 2px;
      font-family: AlibabaPuHuiTiM;
      white-space: nowrap;
      line-height: 30px;
      text-align: left;
      margin-top: 20px;
    }
    .ptn-infoBox5 {
      width: 645px;
      height: 90px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(102, 102, 102, 1);
      font-size: 14px;
      letter-spacing: 1.75px;
      font-family: AlibabaPuHuiTiR;
      line-height: 30px;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .ptn-txt30 {
      width: 89px;
      height: 30px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(40, 40, 40, 1);
      font-size: 16px;
      letter-spacing: 2px;
      font-family: AlibabaPuHuiTiM;
      white-space: nowrap;
      line-height: 30px;
      text-align: left;
      margin-top: 8px;
    }
    .ptn-paragraph4 {
      width: 645px;
      height: 120px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(102, 102, 102, 1);
      font-size: 14px;
      letter-spacing: 1.75px;
      font-family: AlibabaPuHuiTiR;
      line-height: 30px;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .ptn-outer7 {
      width: 309px;
      height: 48px;
      margin: 43px 0 0 66px;
      .ptn-txt40 {
        z-index: 724;
        position: absolute;
        left: 1009px;
        top: 5934px;
        width: 86px;
        height: 55px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(37, 184, 47, 1);
        font-size: 40px;
        letter-spacing: 2px;
        font-family: AlibabaPuHuiTiM;
        white-space: nowrap;
        line-height: 55px;
        text-align: left;
      }
      .ptn-word67 {
        z-index: 725;
        position: absolute;
        left: 1219px;
        top: 5934px;
        width: 86px;
        height: 55px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(37, 184, 47, 1);
        font-size: 40px;
        letter-spacing: 2px;
        font-family: AlibabaPuHuiTiM;
        white-space: nowrap;
        line-height: 55px;
        text-align: left;
      }
      .ptn-info22 {
        width: 63px;
        height: 20px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(153, 153, 153, 1);
        font-size: 14px;
        letter-spacing: 1.75px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 20px;
        text-align: left;
        margin-top: 28px;
      }
      .ptn-box8 {
        width: 17px;
        height: 31px;
        background: url(../../assets/images/procedure/hook.png) -1px -2px
          no-repeat;
        background-size: 23px 34px;
        margin-left: 19px;
      }
      .ptn-word47 {
        width: 47px;
        height: 20px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(153, 153, 153, 1);
        font-size: 14px;
        letter-spacing: 1.75px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 20px;
        text-align: left;
        margin: 28px 0 0 111px;
      }
      .ptn-box9 {
        width: 17px;
        height: 31px;
        background: url(../../assets/images/procedure/hook.png) -1px -2px
          no-repeat;
        background-size: 23px 34px;
        margin: 1px 0 0 35px;
      }
    }
    .ptn-outer8 {
      width: 300px;
      height: 60px;
      margin-top: 27px;
      .ptn-bd16 {
        height: 60px;
        background-color: rgba(37, 184, 47, 1);
        box-shadow: 0px 12px 24px 0px rgba(37, 184, 47, 0.2);
        width: 180px;
        cursor: pointer;
        .ptn-word48 {
          width: 85px;
          height: 29px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 21px;
          letter-spacing: 0.41999998688697815px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 29px;
          text-align: left;
          margin: 15px 0 0 47px;
        }
      }
      .ptn-label18 {
        width: 40px;
        height: 40px;
        background: url(../../assets/images/procedure/grethan.png) 100%
          no-repeat;
        margin-top: 10px;
        margin-right: 2px;
      }
      .ptn-label18:hover {
        background: url(../../assets/images/procedure/grethangreen.png) -24px -12px
          no-repeat;
      }
    }
  }
}
</style>
<style rel="stylesheet/scss" lang="scss" >
.ptm-page {
  position: relative;
  width: 1920px;
  height: 7671px;
  margin: 0 auto;
  background-color: rgba(250, 250, 250, 1);
  overflow: hidden;
  .ptm-main1 {
    width: 1920px;
    height: 7671px;
    .ptm-mod1 {
      // z-index: 6;
      height: 920px;
      overflow: hidden;
      background: url(../../assets/images/back/graythird.png) 100% no-repeat;
      width: 1920px;
      position: relative;
      .ptm-section1 {
        z-index: 80;
        width: 1920px;
        height: 610px;
        .ptm-layer1 {
          height: 100px;
          // background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4fabcf914c36d5ea3cd07d6ce078e0458ab67025b5599f23ef90745c2e46e937)
          //   100% no-repeat;
          width: 1920px;
          .ptm-block1 {
            width: 1775px;
            height: 42px;
            margin: 29px 0 0 65px;
            .ptm-pic1 {
              width: 180px;
              height: 38px;
              margin-top: 2px;
            }
            .ptm-txt1 {
              width: 43px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 0.88);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 301px;
            }
            .ptm-word1 {
              width: 85px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiB;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 104px;
            }
            .ptm-info1 {
              width: 85px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 0.88);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 104px;
            }
            .ptm-txt2 {
              width: 85px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 0.88);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 104px;
            }
            .ptm-txt3 {
              width: 85px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 0.88);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 104px;
            }
            .ptm-box1 {
              height: 42px;
              border-radius: 21px;
              border: 2px solid rgba(37, 184, 47, 1);
              margin-left: 346px;
              width: 149px;
              .ptm-txt4 {
                width: 85px;
                height: 29px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(255, 255, 255, 0.94);
                font-size: 21px;
                letter-spacing: 0.41999998688697815px;
                font-family: AlibabaPuHuiTiR;
                white-space: nowrap;
                line-height: 29px;
                text-align: left;
                margin: 7px 0 0 32px;
              }
            }
          }
        }
        .ptm-word2 {
          width: 426px;
          height: 65px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 48px;
          letter-spacing: 0.9599999785423279px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 65px;
          text-align: left;
          margin: 221px 0 0 747px;
        }
        .ptm-word3 {
          width: 302px;
          height: 38px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 28px;
          letter-spacing: 1.75px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 38px;
          text-align: center;
          margin: 36px 0 0 809px;
        }
        .ptm-layer2 {
          height: 68px;
          border-radius: 34px;
          background-color: rgba(37, 184, 47, 1);
          box-shadow: 0px 20px 40px 0px rgba(37, 184, 47, 0.32);
          width: 220px;
          margin: 82px 0 0 850px;
          .ptm-word4 {
            width: 113px;
            height: 38px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 28px;
            letter-spacing: 0.5600000023841858px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 38px;
            text-align: left;
            margin: 15px 0 0 54px;
          }
        }
      }
      .ptm-section2 {
        z-index: 7;
        height: 920px;
        background: url(../../assets/main/tms.png) 100% no-repeat;
        width: 1920px;
        position: absolute;
        left: 0;
        top: 0;
        .ptm-layer3 {
          width: 1920px;
          height: 920px;
          // background-color: rgba(0, 0, 0, 0.5);
        }
      }
    }
    .ptm-txt5 {
      width: 129px;
      height: 44px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(39, 39, 39, 1);
      font-size: 32px;
      letter-spacing: 0.6399999856948853px;
      font-family: AlibabaPuHuiTiM;
      white-space: nowrap;
      line-height: 44px;
      text-align: left;
      margin: 120px 0 0 896px;
    }
    .ptm-info2 {
      width: 898px;
      height: 64px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(102, 102, 102, 1);
      font-size: 16px;
      letter-spacing: 1.3333333730697632px;
      font-family: AlibabaPuHuiTiR;
      line-height: 32px;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 24px 0 0 511px;
    }
    .ptm-mod2 {
      width: 1206px;
      height: 126px;
      margin: 85px 0 0 359px;
      .ptm-img17 {
        width: 124px;
        height: 124px;
        margin-top: 1px;
      }
      .ptm-bd18 {
        width: 126px;
        height: 126px;
        background: url(../../assets/images/procedure/tmstwo.png) -2px -2px
          no-repeat;
        margin-left: 235px;
      }
      .ptm-bd19 {
        width: 138px;
        height: 107px;
        background: url(../../assets/images/procedure/tmsthird.png) -1px -2px
          no-repeat;
        margin: 10px 0 0 229px;
      }
      .ptm-pic24 {
        width: 128px;
        height: 125px;
        margin: 1px 0 0 226px;
      }
    }
    .ptm-mod5 {
      width: 1144px;
      height: 29px;
      margin: 49px 0 0 378px;
      .ptm-info3 {
        width: 85px;
        height: 29px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 21px;
        letter-spacing: 0.41999998688697815px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 29px;
        text-align: left;
      }
      .ptm-word5 {
        width: 85px;
        height: 29px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 21px;
        letter-spacing: 0.41999998688697815px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 29px;
        text-align: left;
        margin-left: 275px;
      }
      .ptm-info4 {
        width: 85px;
        height: 29px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 21px;
        letter-spacing: 0.41999998688697815px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 29px;
        text-align: center;
        margin-left: 275px;
      }
      .ptm-info5 {
        width: 43px;
        height: 29px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 21px;
        letter-spacing: 0.41999998688697815px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 29px;
        text-align: left;
        margin-left: 296px;
      }
    }
    .ptm-mod6 {
      width: 1325px;
      height: 56px;
      margin: 16px 0 0 298px;
      .ptm-txt6 {
        width: 245px;
        height: 56px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 28px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .ptm-info6 {
        width: 245px;
        height: 56px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 28px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .ptm-txt7 {
        width: 245px;
        height: 56px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 28px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .ptm-word6 {
        width: 245px;
        height: 56px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 28px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .ptm-mod7 {
      // z-index: 81;
      height: 946px;
      background-color: rgba(37, 38, 37, 1);
      margin-top: 120px;
      width: 1920px;
      position: relative;
      .ptm-section3 {
        width: 1404px;
        height: 810px;
        margin: 68px 0 0 1px;
        .ptm-word7 {
          width: 129px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 32px;
          letter-spacing: 0.6399999856948853px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 44px;
          text-align: left;
          margin-left: 896px;
        }
        .ptm-word8 {
          width: 404px;
          height: 32px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 18px;
          letter-spacing: 1.5px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 32px;
          text-align: center;
          margin: 24px 0 0 759px;
        }
        .ptm-main2 {
          width: 84px;
          height: 6px;
          background-color: rgba(37, 184, 47, 1);
          margin: 12px 0 0 919px;
        }
        .ptm-main3 {
          width: 1404px;
          height: 644px;
          margin-top: 48px;
          .ptm-mod8 {
            // z-index: 148;
            height: 644px;
            overflow: hidden;
            // background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng7c8d0199be73cfe217f6ee6e9d29deba7008af0318b395606c61364f29ff7564)
            //   100% no-repeat;
            width: 884px;
            position: relative;
            .ptm-block2 {
              z-index: 2;
              height: 644px;
              overflow: hidden;
              background: url(../../assets/images/back/tmsgreyone.png) 100%
                no-repeat;
              width: 884px;
              position: relative;
              .ptm-group1 {
                width: 245px;
                height: 257px;
                margin: 248px 0 0 200px;
                .ptm-info7 {
                  width: 245px;
                  height: 50px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(255, 255, 255, 1);
                  font-size: 36px;
                  letter-spacing: 0.7200000286102295px;
                  font-family: AlibabaPuHuiTiM;
                  white-space: nowrap;
                  line-height: 50px;
                  text-align: left;
                }
                .ptm-paragraph1 {
                  width: 245px;
                  height: 56px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(255, 255, 255, 1);
                  font-size: 16px;
                  letter-spacing: 1.5px;
                  font-family: AlibabaPuHuiTiM;
                  line-height: 28px;
                  text-align: left;
                  margin-top: 16px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                .ptm-pic3 {
                  width: 55px;
                  height: 55px;
                  margin-top: 80px;
                }
              }
            }
            .ptm-img2 {
              // z-index: 149;
              position: absolute;
              left: 0;
              top: 0;
              width: 884px;
              height: 644px;
            }
          }
          .ptm-mod9 {
            // z-index: 152;
            height: 644px;
            overflow: hidden;
            // background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng0f5726f0f350738196c36ea66a8a5d6215bca306970ea80462c7ea2db7512a35)
            //   100% no-repeat;
            width: 520px;
            position: relative;
            .ptm-section4 {
              z-index: 2;
              height: 644px;
              overflow: hidden;
              background: url(../../assets/images/back/tmsgreytwo.png) 100%
                no-repeat;
              width: 520px;
              position: relative;
              .ptm-block3 {
                width: 246px;
                height: 257px;
                margin: 248px 0 0 119px;
                .ptm-word9 {
                  width: 145px;
                  height: 50px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(255, 255, 255, 1);
                  font-size: 36px;
                  letter-spacing: 0.7200000286102295px;
                  font-family: AlibabaPuHuiTiM;
                  white-space: nowrap;
                  line-height: 50px;
                  text-align: left;
                  margin-left: 1px;
                }
                .ptm-paragraph2 {
                  width: 245px;
                  height: 56px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(255, 255, 255, 1);
                  font-size: 16px;
                  letter-spacing: 1.5px;
                  font-family: AlibabaPuHuiTiM;
                  line-height: 28px;
                  text-align: left;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  margin: 16px 0 0 1px;
                }
                .ptm-pic4 {
                  width: 55px;
                  height: 55px;
                  margin-top: 80px;
                }
              }
            }
            .ptm-pic5 {
              // z-index: 153;
              position: absolute;
              left: 0;
              top: 0;
              width: 520px;
              height: 644px;
            }
          }
        }
      }
      .ptm-section5 {
        z-index: 1;
        height: 644px;
        overflow: hidden;
        // background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng038e821d2f8db7e3214e2a08ecf379d796bc14fe9aa747cc47e05e9c1b8243d3)
        //   100% no-repeat;
        width: 517px;
        position: absolute;
        left: 1403px;
        top: 234px;
        .ptm-mod10 {
          z-index: 2;
          height: 644px;
          overflow: hidden;
          background: url(../../assets/images/back/tmsgreythird.png) 100%
            no-repeat;
          width: 517px;
          position: relative;
          .ptm-wrap1 {
            width: 245px;
            height: 257px;
            margin: 248px 0 0 120px;
            .ptm-word10 {
              width: 217px;
              height: 50px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 36px;
              letter-spacing: 0.7200000286102295px;
              font-family: AlibabaPuHuiTiM;
              white-space: nowrap;
              line-height: 50px;
              text-align: left;
            }
            .ptm-paragraph3 {
              width: 245px;
              height: 84px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 1.5px;
              font-family: AlibabaPuHuiTiM;
              line-height: 28px;
              text-align: left;
              margin-top: 16px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .ptm-img3 {
              width: 55px;
              height: 55px;
              margin-top: 52px;
            }
          }
        }
        .ptm-img4 {
          // z-index: 666;
          position: absolute;
          left: 0;
          top: 0;
          width: 517px;
          height: 644px;
        }
      }
    }
    .ptm-word11 {
      width: 284px;
      height: 44px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(40, 40, 40, 1);
      font-size: 32px;
      letter-spacing: 0.6399999856948853px;
      font-family: AlibabaPuHuiTiM;
      white-space: nowrap;
      line-height: 44px;
      text-align: center;
      margin: 120px 0 0 818px;
    }
    .ptm-word12 {
      width: 898px;
      height: 32px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(102, 102, 102, 1);
      font-size: 18px;
      letter-spacing: 1.5px;
      font-family: AlibabaPuHuiTiR;
      white-space: nowrap;
      line-height: 32px;
      text-align: center;
      margin: 24px 0 0 511px;
    }
    .ptm-mod11 {
      width: 1520px;
      height: 409px;
      margin: 52px 0 0 200px;
      .ptm-mod12 {
        height: 396px;
        border: 1px solid rgba(245, 245, 245, 1);
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
        width: 488px;
        .ptm-outer1 {
          width: 116px;
          height: 185px;
          margin: 76px 0 0 183px;
          .ptm-word13 {
            width: 97px;
            height: 33px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(40, 40, 40, 1);
            font-size: 24px;
            letter-spacing: 0.47999998927116394px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 33px;
            text-align: center;
            margin-left: 12px;
          }
          .ptm-section6 {
            width: 116px;
            height: 28px;
            margin-top: 28px;
            .ptm-section13 {
              width: 21px;
              height: 21px;
              background: url(../../assets/images/procedure/tick.png) 0px 0px
                no-repeat;
              margin-top: 4px;
            }
            .ptm-word14 {
              width: 86px;
              height: 28px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(102, 102, 102, 1);
              font-size: 16px;
              letter-spacing: 1.3333333730697632px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 28px;
              text-align: left;
            }
          }
          .ptm-section7 {
            width: 116px;
            height: 28px;
            margin-top: 20px;
            .ptm-bd20 {
              width: 21px;
              height: 21px;
              background: url(../../assets/images/procedure/tick.png) 0px 0px
                no-repeat;
              margin-top: 4px;
            }
            .ptm-word15 {
              width: 86px;
              height: 28px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(102, 102, 102, 1);
              font-size: 16px;
              letter-spacing: 1.3333333730697632px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 28px;
              text-align: left;
            }
          }
          .ptm-section8 {
            width: 116px;
            height: 28px;
            margin-top: 20px;
            .ptm-layer12 {
              width: 21px;
              height: 22px;
              background: url(../../assets/images/procedure/tick.png) 0px 0px
                no-repeat;
              margin-top: 4px;
            }
            .ptm-txt8 {
              width: 86px;
              height: 28px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(102, 102, 102, 1);
              font-size: 16px;
              letter-spacing: 1.3333333730697632px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 28px;
              text-align: left;
            }
          }
        }
      }
      .ptm-mod12:hover {
        background-color: rgba(37, 184, 47, 1);
        box-shadow: 0px 20px 40px 0px rgba(37, 184, 47, 0.24);
        .ptm-word13,
        .ptm-section6 .ptm-word14,
        .ptm-section7 .ptm-word15,
        .ptm-section8 .ptm-txt8 {
          color: rgba(255, 255, 255, 1);
        }
        .ptm-section6 .ptm-section13,
        .ptm-section7 .ptm-bd20,
        .ptm-section8 .ptm-layer12 {
          background: url(../../assets/images/procedure/whitetick.png) 0px 0px
            no-repeat;
        }
        .ptm-mod13 {
          width: 259px;
          height: 165px;
          background: url(../../assets/images/back/greencloth.png) 100%
            no-repeat;
          margin: 50px 0 0 114px;
        }
      }
      .ptm-mod14 {
        height: 396px;
        border: 1px solid rgba(245, 245, 245, 1);
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
        width: 487px;
        .ptm-box3 {
          width: 97px;
          height: 33px;
          margin: 76px 0 0 196px;
          .ptm-txt9 {
            width: 97px;
            height: 33px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(40, 40, 40, 1);
            font-size: 24px;
            letter-spacing: 0.47999998927116394px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 33px;
            text-align: center;
          }
        }
        .ptm-box4 {
          width: 116px;
          height: 28px;
          margin: 28px 0 0 132px;
          .ptm-box24 {
            width: 21px;
            height: 21px;
            background: url(../../assets/images/procedure/tick.png) 0px 0px
              no-repeat;
            margin-top: 4px;
          }
          .ptm-info8 {
            width: 86px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 28px;
            text-align: left;
          }
        }
        .ptm-box5 {
          width: 116px;
          height: 28px;
          margin: 20px 0 0 132px;
          .ptm-outer12 {
            width: 21px;
            height: 21px;
            background: url(../../assets/images/procedure/tick.png) 0px 0px
              no-repeat;
            margin-top: 4px;
          }
          .ptm-txt10 {
            width: 86px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 28px;
            text-align: left;
          }
        }
        .ptm-box6 {
          width: 218px;
          height: 56px;
          margin: 20px 0 107px 132px;
          .ptm-block15 {
            width: 21px;
            height: 22px;
            background: url(../../assets/images/procedure/tick.png) 0px 0px
              no-repeat;
            margin-top: 4px;
          }
          .ptm-infoBox1 {
            width: 188px;
            height: 56px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiR;
            line-height: 28px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .ptm-mod14:hover {
        background-color: rgba(37, 184, 47, 1);
        box-shadow: 0px 20px 40px 0px rgba(37, 184, 47, 0.24);
        .ptm-txt9,
        .ptm-box4 .ptm-info8,
        .ptm-box5 .ptm-txt10,
        .ptm-box6 .ptm-infoBox1 {
          color: rgba(255, 255, 255, 1);
        }
        .ptm-box4 .ptm-box24,
        .ptm-box5 .ptm-outer12,
        .ptm-box6 .ptm-block15 {
          background: url(../../assets/images/procedure/whitetick.png) 0px 0px
            no-repeat;
        }
        .ptm-mod13 {
          width: 259px;
          height: 165px;
          background: url(../../assets/images/back/greencloth.png) 100%
            no-repeat;
          margin: -83px 0 0 114px;
          // margin-top: -12px;
          // margin-left: 172px;
        }
      }
    }
    .ptm-mod15 {
      width: 1920px;
      height: 838px;
      margin-top: 107px;
      .ptm-main4 {
        height: 838px;
        background-color: rgba(37, 184, 47, 1);
        width: 1920px;
        .ptm-bd1 {
          width: 129px;
          height: 44px;
          margin: 80px 0 0 896px;
          .ptm-word16 {
            width: 129px;
            height: 44px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 32px;
            letter-spacing: 0.6399999856948853px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 44px;
            text-align: left;
          }
        }
        .ptm-bd2 {
          width: 84px;
          height: 6px;
          margin: 24px 0 0 918px;
          .ptm-group2 {
            width: 84px;
            height: 6px;
            background-color: rgba(255, 255, 255, 1);
          }
        }
        .ptm-bd3 {
          width: 1111px;
          height: 120px;
          margin: 76px 0 0 405px;
          .ptm-pic25 {
            width: 120px;
            height: 120px;
          }
          .ptm-img18 {
            width: 120px;
            height: 120px;
          }
          .ptm-img19 {
            width: 120px;
            height: 120px;
          }
          .ptm-img20 {
            width: 120px;
            height: 120px;
          }
          .ptm-img21 {
            width: 120px;
            height: 120px;
          }
        }
        .ptm-bd4 {
          width: 1060px;
          height: 28px;
          margin: 20px 0 0 431px;
          .ptm-info9 {
            width: 69px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
          }
          .ptm-info10 {
            width: 69px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
          }
          .ptm-word17 {
            width: 69px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
          }
          .ptm-info11 {
            width: 69px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
          }
          .ptm-word18 {
            width: 69px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
          }
        }
        .ptm-bd5 {
          width: 1179px;
          height: 44px;
          margin: 4px 0 0 371px;
          .ptm-word19 {
            width: 188px;
            height: 44px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 14px;
            letter-spacing: 1.1666666269302368px;
            font-family: AlibabaPuHuiTiR;
            line-height: 22px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .ptm-txt11 {
            width: 188px;
            height: 44px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 14px;
            letter-spacing: 1.1666666269302368px;
            font-family: AlibabaPuHuiTiR;
            line-height: 22px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .ptm-word20 {
            width: 188px;
            height: 44px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 14px;
            letter-spacing: 1.1666666269302368px;
            font-family: AlibabaPuHuiTiR;
            line-height: 22px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .ptm-word21 {
            width: 188px;
            height: 44px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 14px;
            letter-spacing: 1.1666666269302368px;
            font-family: AlibabaPuHuiTiR;
            line-height: 22px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .ptm-txt12 {
            width: 188px;
            height: 44px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 14px;
            letter-spacing: 1.1666666269302368px;
            font-family: AlibabaPuHuiTiR;
            line-height: 22px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .ptm-bd6 {
          width: 1111px;
          height: 120px;
          margin: 54px 0 0 405px;
          .ptm-img22 {
            width: 120px;
            height: 120px;
          }
          .ptm-img23 {
            width: 120px;
            height: 120px;
          }
          .ptm-pic26 {
            width: 120px;
            height: 120px;
          }
          .ptm-img24 {
            width: 120px;
            height: 120px;
          }
          .ptm-pic27 {
            width: 120px;
            height: 120px;
          }
        }
        .ptm-bd7 {
          width: 1060px;
          height: 28px;
          margin: 20px 0 0 431px;
          .ptm-word22 {
            width: 69px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
          }
          .ptm-txt13 {
            width: 69px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
          }
          .ptm-word23 {
            width: 69px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
          }
          .ptm-txt14 {
            width: 69px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
          }
          .ptm-info12 {
            width: 69px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
          }
        }
        .ptm-bd8 {
          width: 1180px;
          height: 44px;
          margin: 4px 0 122px 371px;
          .ptm-word24 {
            width: 188px;
            height: 44px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 14px;
            letter-spacing: 1.1666666269302368px;
            font-family: AlibabaPuHuiTiR;
            line-height: 22px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .ptm-word25 {
            width: 188px;
            height: 44px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 14px;
            letter-spacing: 1.1666666269302368px;
            font-family: AlibabaPuHuiTiR;
            line-height: 22px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .ptm-word26 {
            width: 188px;
            height: 44px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 14px;
            letter-spacing: 1.1666666269302368px;
            font-family: AlibabaPuHuiTiR;
            line-height: 22px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .ptm-word27 {
            width: 188px;
            height: 44px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 14px;
            letter-spacing: 1.1666666269302368px;
            font-family: AlibabaPuHuiTiR;
            line-height: 22px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .ptm-txt15 {
            width: 188px;
            height: 44px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 14px;
            letter-spacing: 1.1666666269302368px;
            font-family: AlibabaPuHuiTiR;
            line-height: 22px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .ptm-word28 {
        width: 60px;
        height: 24px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 24px;
        text-align: center;
        margin: 662px 0 0 -1486px;
      }
      .ptm-info13 {
        width: 60px;
        height: 24px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 24px;
        text-align: center;
        margin: 662px 0 0 188px;
      }
      .ptm-info14 {
        width: 60px;
        height: 24px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 24px;
        text-align: center;
        margin: 662px 0 0 187px;
      }
      .ptm-info15 {
        width: 60px;
        height: 24px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 24px;
        text-align: center;
        margin: 662px 0 0 188px;
      }
      .ptm-word29 {
        width: 60px;
        height: 24px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 24px;
        text-align: center;
        margin: 662px 435px 0 188px;
      }
    }
    .ptm-info16 {
      width: 134px;
      height: 44px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(40, 40, 40, 1);
      font-size: 32px;
      letter-spacing: 2px;
      font-family: AlibabaPuHuiTiM;
      white-space: nowrap;
      line-height: 44px;
      text-align: left;
      margin: 120px 0 0 893px;
    }
    .ptm-txt16 {
      width: 898px;
      height: 32px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(102, 102, 102, 1);
      font-size: 18px;
      letter-spacing: 1.5px;
      font-family: AlibabaPuHuiTiR;
      white-space: nowrap;
      line-height: 32px;
      text-align: center;
      margin: 24px 0 0 511px;
    }
    .ptm-mod16 {
      // z-index: 271;
      height: 822px;
      background-color: rgba(255, 255, 255, 1);
      margin-top: 36px;
      width: 1920px;
      position: relative;
      .ptm-section9 {
        width: 1374px;
        height: 540px;
        margin: 128px 0 0 272px;
        background: url(../../assets/images/procedure/tmsflow.png) 100%
          no-repeat;
        .ptm-outer4 {
          height: 540px;
          background-color: rgba(0, 255, 17, 0.05);
          width: 278px;
          .ptm-mod17 {
            z-index: 276;
            height: 500px;
            border: 1px solid rgba(37, 184, 47, 1);
            width: 230px;
            position: relative;
            margin: 20px 0 0 24px;
            .ptm-block4 {
              width: 173px;
              height: 358px;
              margin: 73px 0 0 26px;
              .ptm-mod18 {
                height: 80px;
                background-color: rgba(0, 255, 17, 0.16);
                width: 173px;
                .ptm-paragraph4 {
                  width: 103px;
                  height: 48px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(40, 40, 40, 1);
                  font-size: 16px;
                  letter-spacing: 1.3333333730697632px;
                  font-family: AlibabaPuHuiTiR;
                  line-height: 24px;
                  text-align: center;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  margin: 16px 0 0 35px;
                }
              }
              .ptm-mod19 {
                width: 1px;
                height: 60px;
                background: url(../../assets/images/procedure/greenarrow.png) -4px -1px
                  no-repeat;
                margin-left: 130px;
              }
              .ptm-mod20 {
                width: 173px;
                height: 96px;
                .ptm-bd9 {
                  height: 96px;
                  background-color: rgba(0, 255, 17, 0.16);
                  width: 85px;
                  .ptm-infoBox2 {
                    width: 69px;
                    height: 72px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(40, 40, 40, 1);
                    font-size: 16px;
                    letter-spacing: 1.3333333730697632px;
                    font-family: AlibabaPuHuiTiR;
                    line-height: 24px;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 12px 0 0 8px;
                  }
                }
                .ptm-bd10 {
                  height: 96px;
                  background-color: rgba(0, 255, 17, 0.16);
                  width: 85px;
                  .ptm-paragraph5 {
                    width: 69px;
                    height: 72px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(40, 40, 40, 1);
                    font-size: 16px;
                    letter-spacing: 1.3333333730697632px;
                    font-family: AlibabaPuHuiTiR;
                    line-height: 24px;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 12px 0 0 8px;
                  }
                }
              }
              .ptm-mod21 {
                width: 1px;
                height: 60px;
                background: url(../../assets/images/procedure/greenarrow.png) -4px -1px
                  no-repeat;
                margin: 2px 0 0 86px;
              }
              .ptm-mod22 {
                height: 60px;
                background-color: rgba(37, 184, 47, 1);
                width: 173px;
                .ptm-txt17 {
                  width: 137px;
                  height: 32px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(255, 255, 255, 1);
                  font-size: 16px;
                  letter-spacing: 1.3333333730697632px;
                  font-family: AlibabaPuHuiTiM;
                  line-height: 32px;
                  text-align: center;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  margin: 14px 0 0 18px;
                }
              }
            }
            .ptm-block5 {
              z-index: 614;
              position: absolute;
              left: 95px;
              top: 238px;
              width: 36px;
              height: 1px;
              // background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng7c0d0b897faf7d9af2d3feb2810c8f73e8adac27c2b0f73bbb5f2350b5eaba75) -1px -4px
              //   no-repeat;
            }
          }
        }
        .ptm-outer5 {
          height: 540px;
          background-color: rgba(255, 194, 0, 0.06);
          width: 1076px;
          .ptm-block6 {
            width: 1028px;
            height: 500px;
            margin: 20px 0 0 24px;
            .ptm-bd11 {
              height: 274px;
              border: 1px solid rgba(37, 184, 47, 1);
              width: 1028px;
              .ptm-outer6 {
                width: 928px;
                height: 112px;
                margin: 40px 0 0 40px;
                .ptm-box7 {
                  height: 112px;
                  border-radius: 8px;
                  background-color: rgba(0, 255, 17, 0.16);
                  width: 40px;
                  .ptm-info17 {
                    width: 16px;
                    height: 96px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(40, 40, 40, 1);
                    font-size: 16px;
                    letter-spacing: 1.3333333730697632px;
                    font-family: AlibabaPuHuiTiR;
                    line-height: 24px;
                    text-align: left;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 8px 0 0 12px;
                  }
                }
                .ptm-box8 {
                  width: 60px;
                  height: 78px;
                  margin-left: 42px;
                  .ptm-icon33 {
                    width: 48px;
                    height: 48px;
                    margin-left: 6px;
                  }
                  .ptm-word30 {
                    width: 60px;
                    height: 24px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(40, 40, 40, 1);
                    font-size: 14px;
                    letter-spacing: 1.1666666269302368px;
                    font-family: AlibabaPuHuiTiR;
                    white-space: nowrap;
                    line-height: 24px;
                    text-align: left;
                    margin-top: 6px;
                  }
                }
                .ptm-label2 {
                  width: 10px;
                  height: 14px;
                  margin: 18px 0 0 9px;
                }
                .ptm-box9 {
                  width: 60px;
                  height: 112px;
                  margin-left: 9px;
                  .ptm-label42 {
                    width: 48px;
                    height: 48px;
                    margin-left: 6px;
                  }
                  .ptm-info18 {
                    width: 60px;
                    height: 24px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(40, 40, 40, 1);
                    font-size: 14px;
                    letter-spacing: 1.1666666269302368px;
                    font-family: AlibabaPuHuiTiR;
                    white-space: nowrap;
                    line-height: 24px;
                    text-align: left;
                    margin-top: 6px;
                  }
                  .ptm-label4 {
                    width: 18px;
                    height: 26px;
                    margin: 8px 0 0 21px;
                  }
                }
                .ptm-icon1 {
                  width: 10px;
                  height: 14px;
                  margin: 18px 0 0 9px;
                }
                .ptm-box10 {
                  width: 60px;
                  height: 112px;
                  margin-left: 9px;
                  .ptm-icon34 {
                    width: 48px;
                    height: 48px;
                    margin-left: 6px;
                  }
                  .ptm-info19 {
                    width: 60px;
                    height: 24px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(40, 40, 40, 1);
                    font-size: 14px;
                    letter-spacing: 1.1666666269302368px;
                    font-family: AlibabaPuHuiTiR;
                    white-space: nowrap;
                    line-height: 24px;
                    text-align: left;
                    margin-top: 6px;
                  }
                  .ptm-label6 {
                    width: 18px;
                    height: 26px;
                    margin: 8px 0 0 21px;
                  }
                }
                .ptm-icon2 {
                  width: 10px;
                  height: 14px;
                  margin: 18px 0 0 9px;
                }
                .ptm-box11 {
                  width: 60px;
                  height: 112px;
                  margin-left: 9px;
                  .ptm-icon35 {
                    width: 48px;
                    height: 48px;
                    margin-left: 6px;
                  }
                  .ptm-info20 {
                    width: 60px;
                    height: 24px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(40, 40, 40, 1);
                    font-size: 14px;
                    letter-spacing: 1.1666666269302368px;
                    font-family: AlibabaPuHuiTiR;
                    white-space: nowrap;
                    line-height: 24px;
                    text-align: left;
                    margin-top: 6px;
                  }
                  .ptm-label7 {
                    width: 18px;
                    height: 26px;
                    margin: 8px 0 0 21px;
                  }
                }
                .ptm-label8 {
                  width: 10px;
                  height: 14px;
                  margin: 18px 0 0 10px;
                }
                .ptm-box12 {
                  width: 60px;
                  height: 78px;
                  margin-left: 8px;
                  .ptm-icon36 {
                    width: 48px;
                    height: 48px;
                    margin-left: 6px;
                  }
                  .ptm-info21 {
                    width: 60px;
                    height: 24px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(40, 40, 40, 1);
                    font-size: 14px;
                    letter-spacing: 1.1666666269302368px;
                    font-family: AlibabaPuHuiTiR;
                    white-space: nowrap;
                    line-height: 24px;
                    text-align: left;
                    margin-top: 6px;
                  }
                }
                .ptm-icon5 {
                  width: 10px;
                  height: 14px;
                  margin: 18px 0 0 10px;
                }
                .ptm-box13 {
                  width: 60px;
                  height: 112px;
                  margin-left: 8px;
                  .ptm-label43 {
                    width: 48px;
                    height: 48px;
                    margin-left: 6px;
                  }
                  .ptm-txt18 {
                    width: 60px;
                    height: 24px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(40, 40, 40, 1);
                    font-size: 14px;
                    letter-spacing: 1.1666666269302368px;
                    font-family: AlibabaPuHuiTiR;
                    white-space: nowrap;
                    line-height: 24px;
                    text-align: left;
                    margin-top: 6px;
                  }
                  .ptm-label9 {
                    width: 18px;
                    height: 26px;
                    margin: 8px 0 0 21px;
                  }
                }
                .ptm-icon7 {
                  width: 10px;
                  height: 14px;
                  margin: 18px 0 0 10px;
                }
                .ptm-box14 {
                  width: 60px;
                  height: 78px;
                  margin-left: 8px;
                  .ptm-icon37 {
                    width: 48px;
                    height: 48px;
                    margin-left: 6px;
                  }
                  .ptm-word31 {
                    width: 60px;
                    height: 24px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(40, 40, 40, 1);
                    font-size: 14px;
                    letter-spacing: 1.1666666269302368px;
                    font-family: AlibabaPuHuiTiR;
                    white-space: nowrap;
                    line-height: 24px;
                    text-align: left;
                    margin-top: 6px;
                  }
                }
                .ptm-icon9 {
                  width: 10px;
                  height: 14px;
                  margin: 18px 0 0 10px;
                }
                .ptm-box15 {
                  width: 60px;
                  height: 78px;
                  margin-left: 8px;
                  .ptm-icon38 {
                    width: 48px;
                    height: 48px;
                    margin-left: 6px;
                  }
                  .ptm-info22 {
                    width: 60px;
                    height: 24px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(40, 40, 40, 1);
                    font-size: 14px;
                    letter-spacing: 1.1666666269302368px;
                    font-family: AlibabaPuHuiTiR;
                    white-space: nowrap;
                    line-height: 24px;
                    text-align: left;
                    margin-top: 6px;
                  }
                }
                .ptm-label11 {
                  width: 10px;
                  height: 14px;
                  margin: 18px 0 0 10px;
                }
                .ptm-box16 {
                  width: 60px;
                  height: 78px;
                  margin-left: 8px;
                  .ptm-label44 {
                    width: 48px;
                    height: 48px;
                    margin-left: 6px;
                  }
                  .ptm-txt19 {
                    width: 60px;
                    height: 24px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(40, 40, 40, 1);
                    font-size: 14px;
                    letter-spacing: 1.1666666269302368px;
                    font-family: AlibabaPuHuiTiR;
                    white-space: nowrap;
                    line-height: 24px;
                    text-align: left;
                    margin-top: 6px;
                  }
                }
                .ptm-icon10 {
                  width: 10px;
                  height: 14px;
                  margin: 18px 0 0 10px;
                }
                .ptm-box17 {
                  width: 48px;
                  height: 78px;
                  margin-left: 14px;
                  .ptm-label45 {
                    width: 48px;
                    height: 48px;
                  }
                  .ptm-word32 {
                    width: 30px;
                    height: 24px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(40, 40, 40, 1);
                    font-size: 14px;
                    letter-spacing: 1.1666666269302368px;
                    font-family: AlibabaPuHuiTiR;
                    white-space: nowrap;
                    line-height: 24px;
                    text-align: left;
                    margin: 6px 0 0 9px;
                  }
                }
              }
              .ptm-outer7 {
                width: 400px;
                height: 48px;
                margin: 8px 0 0 216px;
                .ptm-label46 {
                  width: 48px;
                  height: 48px;
                }
                .ptm-icon39 {
                  width: 48px;
                  height: 48px;
                  margin-left: 40px;
                }
                .ptm-icon40 {
                  width: 48px;
                  height: 48px;
                  margin-left: 40px;
                }
                .ptm-icon41 {
                  width: 48px;
                  height: 48px;
                  margin-left: 128px;
                }
              }
              .ptm-outer8 {
                width: 412px;
                height: 24px;
                margin: 6px 0 36px 210px;
                .ptm-word33 {
                  width: 60px;
                  height: 24px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(40, 40, 40, 1);
                  font-size: 14px;
                  letter-spacing: 1.1666666269302368px;
                  font-family: AlibabaPuHuiTiR;
                  white-space: nowrap;
                  line-height: 24px;
                  text-align: left;
                }
                .ptm-txt20 {
                  width: 60px;
                  height: 24px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(40, 40, 40, 1);
                  font-size: 14px;
                  letter-spacing: 1.1666666269302368px;
                  font-family: AlibabaPuHuiTiR;
                  white-space: nowrap;
                  line-height: 24px;
                  text-align: left;
                  margin-left: 28px;
                }
                .ptm-word34 {
                  width: 60px;
                  height: 24px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(40, 40, 40, 1);
                  font-size: 14px;
                  letter-spacing: 1.1666666269302368px;
                  font-family: AlibabaPuHuiTiR;
                  white-space: nowrap;
                  line-height: 24px;
                  text-align: left;
                  margin-left: 28px;
                }
                .ptm-info23 {
                  width: 60px;
                  height: 24px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(40, 40, 40, 1);
                  font-size: 14px;
                  letter-spacing: 1.1666666269302368px;
                  font-family: AlibabaPuHuiTiR;
                  white-space: nowrap;
                  line-height: 24px;
                  text-align: left;
                  margin-left: 116px;
                }
              }
            }
            .ptm-bd12 {
              height: 180px;
              border: 1px solid rgba(37, 184, 47, 1);
              margin-top: 46px;
              width: 1028px;
              .ptm-outer9 {
                width: 948px;
                height: 112px;
                margin: 38px 0 0 40px;
                .ptm-main5 {
                  height: 112px;
                  border-radius: 8px;
                  background-color: rgba(255, 200, 26, 0.2);
                  width: 40px;
                  .ptm-word35 {
                    width: 16px;
                    height: 96px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(40, 40, 40, 1);
                    font-size: 16px;
                    letter-spacing: 1.3333333730697632px;
                    font-family: AlibabaPuHuiTiR;
                    line-height: 24px;
                    text-align: left;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 8px 0 0 12px;
                  }
                }
                .ptm-main6 {
                  width: 60px;
                  height: 78px;
                  margin: 4px 0 0 42px;
                  .ptm-label47 {
                    width: 48px;
                    height: 48px;
                    margin-left: 6px;
                  }
                  .ptm-word36 {
                    width: 60px;
                    height: 24px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(40, 40, 40, 1);
                    font-size: 14px;
                    letter-spacing: 1.1666666269302368px;
                    font-family: AlibabaPuHuiTiR;
                    white-space: nowrap;
                    line-height: 24px;
                    text-align: left;
                    margin-top: 6px;
                  }
                }
                .ptm-label17 {
                  width: 10px;
                  height: 14px;
                  margin: 22px 0 0 9px;
                }
                .ptm-main7 {
                  width: 60px;
                  height: 78px;
                  margin: 4px 0 0 9px;
                  .ptm-label48 {
                    width: 48px;
                    height: 48px;
                    margin-left: 6px;
                  }
                  .ptm-word37 {
                    width: 60px;
                    height: 24px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(40, 40, 40, 1);
                    font-size: 14px;
                    letter-spacing: 1.1666666269302368px;
                    font-family: AlibabaPuHuiTiR;
                    white-space: nowrap;
                    line-height: 24px;
                    text-align: left;
                    margin-top: 6px;
                  }
                }
                .ptm-label19 {
                  width: 10px;
                  height: 14px;
                  margin: 22px 0 0 9px;
                }
                .ptm-main8 {
                  width: 60px;
                  height: 102px;
                  margin: 4px 0 0 9px;
                  .ptm-label49 {
                    width: 48px;
                    height: 48px;
                    margin-left: 6px;
                  }
                  .ptm-word38 {
                    width: 60px;
                    height: 24px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(40, 40, 40, 1);
                    font-size: 14px;
                    letter-spacing: 1.1666666269302368px;
                    font-family: AlibabaPuHuiTiR;
                    white-space: nowrap;
                    line-height: 24px;
                    text-align: center;
                    margin-top: 6px;
                  }
                  .ptm-txt21 {
                    width: 50px;
                    height: 24px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(102, 102, 102, 1);
                    font-size: 12px;
                    letter-spacing: 1px;
                    font-family: AlibabaPuHuiTiR;
                    line-height: 24px;
                    text-align: center;
                    margin-left: 4px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }
                .ptm-icon13 {
                  width: 10px;
                  height: 14px;
                  margin: 22px 0 0 9px;
                }
                .ptm-main9 {
                  width: 75px;
                  height: 78px;
                  margin: 4px 0 0 1px;
                  .ptm-icon42 {
                    width: 48px;
                    height: 48px;
                    margin-left: 14px;
                  }
                  .ptm-word39 {
                    width: 75px;
                    height: 24px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(40, 40, 40, 1);
                    font-size: 14px;
                    letter-spacing: 1.1666666269302368px;
                    font-family: AlibabaPuHuiTiR;
                    white-space: nowrap;
                    line-height: 24px;
                    text-align: left;
                    margin-top: 6px;
                  }
                }
                .ptm-main10 {
                  width: 108px;
                  height: 102px;
                  margin: 4px 0 0 8px;
                  .ptm-section10 {
                    width: 108px;
                    height: 48px;
                    .ptm-label21 {
                      width: 10px;
                      height: 14px;
                      margin-top: 18px;
                    }
                    .ptm-icon43 {
                      width: 48px;
                      height: 48px;
                    }
                    .ptm-icon16 {
                      width: 10px;
                      height: 14px;
                      margin-top: 18px;
                    }
                  }
                  .ptm-word40 {
                    width: 60px;
                    height: 24px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(40, 40, 40, 1);
                    font-size: 14px;
                    letter-spacing: 1.1666666269302368px;
                    font-family: AlibabaPuHuiTiR;
                    white-space: nowrap;
                    line-height: 24px;
                    text-align: center;
                    margin: 6px 0 0 23px;
                  }
                  .ptm-txt22 {
                    width: 102px;
                    height: 24px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(102, 102, 102, 1);
                    font-size: 12px;
                    letter-spacing: 1px;
                    font-family: AlibabaPuHuiTiR;
                    line-height: 24px;
                    text-align: center;
                    margin-left: 3px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }
                .ptm-main11 {
                  width: 60px;
                  height: 102px;
                  margin: 4px 0 0 13px;
                  .ptm-label50 {
                    width: 48px;
                    height: 48px;
                    margin-left: 6px;
                  }
                  .ptm-txt23 {
                    width: 60px;
                    height: 24px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(40, 40, 40, 1);
                    font-size: 14px;
                    letter-spacing: 1.1666666269302368px;
                    font-family: AlibabaPuHuiTiR;
                    white-space: nowrap;
                    line-height: 24px;
                    text-align: center;
                    margin-top: 6px;
                  }
                  .ptm-info24 {
                    width: 50px;
                    height: 24px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(102, 102, 102, 1);
                    font-size: 12px;
                    letter-spacing: 1px;
                    font-family: AlibabaPuHuiTiR;
                    line-height: 24px;
                    text-align: center;
                    margin-left: 5px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }
                .ptm-label23 {
                  width: 10px;
                  height: 14px;
                  margin: 22px 0 0 10px;
                }
                .ptm-main12 {
                  width: 60px;
                  height: 102px;
                  margin: 4px 0 0 8px;
                  .ptm-icon44 {
                    width: 48px;
                    height: 48px;
                    margin-left: 6px;
                  }
                  .ptm-word41 {
                    width: 60px;
                    height: 24px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(40, 40, 40, 1);
                    font-size: 14px;
                    letter-spacing: 1.1666666269302368px;
                    font-family: AlibabaPuHuiTiR;
                    white-space: nowrap;
                    line-height: 24px;
                    text-align: center;
                    margin-top: 6px;
                  }
                  .ptm-txt24 {
                    width: 50px;
                    height: 24px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(102, 102, 102, 1);
                    font-size: 12px;
                    letter-spacing: 1px;
                    font-family: AlibabaPuHuiTiR;
                    line-height: 24px;
                    text-align: center;
                    margin-left: 5px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }
                .ptm-label24 {
                  width: 10px;
                  height: 14px;
                  margin: 22px 0 0 10px;
                }
                .ptm-main13 {
                  width: 60px;
                  height: 78px;
                  margin: 4px 0 0 8px;
                  .ptm-icon45 {
                    width: 48px;
                    height: 48px;
                    margin-left: 6px;
                  }
                  .ptm-info25 {
                    width: 60px;
                    height: 24px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(40, 40, 40, 1);
                    font-size: 14px;
                    letter-spacing: 1.1666666269302368px;
                    font-family: AlibabaPuHuiTiR;
                    white-space: nowrap;
                    line-height: 24px;
                    text-align: left;
                    margin-top: 6px;
                  }
                }
                .ptm-icon18 {
                  width: 10px;
                  height: 14px;
                  margin: 22px 0 0 10px;
                }
                .ptm-main14 {
                  width: 75px;
                  height: 78px;
                  margin-top: 4px;
                  .ptm-label51 {
                    width: 48px;
                    height: 48px;
                    margin-left: 14px;
                  }
                  .ptm-word42 {
                    width: 75px;
                    height: 24px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(40, 40, 40, 1);
                    font-size: 14px;
                    letter-spacing: 1.1666666269302368px;
                    font-family: AlibabaPuHuiTiR;
                    white-space: nowrap;
                    line-height: 24px;
                    text-align: left;
                    margin-top: 6px;
                  }
                }
                .ptm-label26 {
                  width: 10px;
                  height: 14px;
                  margin: 22px 0 0 3px;
                }
                .ptm-main15 {
                  width: 48px;
                  height: 78px;
                  margin: 4px 0 0 14px;
                  .ptm-icon46 {
                    width: 48px;
                    height: 48px;
                  }
                  .ptm-word43 {
                    width: 30px;
                    height: 24px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(40, 40, 40, 1);
                    font-size: 14px;
                    letter-spacing: 1.1666666269302368px;
                    font-family: AlibabaPuHuiTiR;
                    white-space: nowrap;
                    line-height: 24px;
                    text-align: left;
                    margin: 6px 0 0 9px;
                  }
                }
              }
            }
          }
        }
      }
      .ptm-img7 {
        z-index: 311;
        position: absolute;
        left: 525px;
        top: 531px;
        width: 69px;
        height: 36px;
      }
    }
    .ptm-txt25 {
      width: 134px;
      height: 44px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(40, 40, 40, 1);
      font-size: 32px;
      letter-spacing: 2px;
      font-family: AlibabaPuHuiTiM;
      white-space: nowrap;
      line-height: 44px;
      text-align: left;
      margin: 120px 0 0 893px;
    }
    .ptm-mod23 {
      width: 1346px;
      height: 609px;
      margin: 48px 0 0 273px;
      .ptm-mod24 {
        z-index: 3;
        height: 611px;
        // border: 1px solid rgba(151, 151, 151, 1);
        overflow: hidden;
        // background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngebcad53e8ca4b0dbad7c24f053f7651d089c0d0deb381fcdbea0291349e538f0)
        //   0px 0px no-repeat;
        width: 592px;
        position: relative;
        margin: -1px 0 0 -1px;
        .ptm-block7 {
          z-index: 170;
          position: relative;
          width: 519px;
          height: 547px;
          // border: 1px solid rgba(151, 151, 151, 1);
          overflow: hidden;
          // background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng783ab9c3f09e0378dcd89384ef85026a16ec01b9c41e206e3bd095d599dd82a5)
          //   0px -1px no-repeat;
          margin: 37px 0 0 57px;
          .ptm-mod25 {
            z-index: 171;
            position: absolute;
            left: -26px;
            top: 0;
            width: 972px;
            height: 545px;
            background: url(../../assets/images/procedure/tmsinsone.png) 25px -1px
              no-repeat;
          }
        }
        .ptm-pic14 {
          z-index: 4;
          position: absolute;
          left: 0;
          top: 0;
          width: 590px;
          height: 609px;
        }
      }
      .ptm-mod26 {
        width: 645px;
        height: 545px;
        margin-top: 36px;
        margin-right: 29px;
        .ptm-word44 {
          width: 284px;
          height: 29px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(40, 40, 40, 1);
          font-size: 21px;
          letter-spacing: 2px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 29px;
          text-align: left;
        }
        .ptm-info26 {
          width: 218px;
          height: 20px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(153, 153, 153, 1);
          font-size: 14px;
          letter-spacing: 1.75px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 20px;
          text-align: left;
          margin-top: 6px;
        }
        .ptm-layer7 {
          width: 84px;
          height: 4px;
          background-color: rgba(37, 184, 47, 1);
          margin-top: 10px;
        }
        .ptm-info27 {
          width: 54px;
          height: 30px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(40, 40, 40, 1);
          font-size: 16px;
          letter-spacing: 2px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 30px;
          text-align: left;
          margin-top: 20px;
        }
        .ptm-infoBox3 {
          width: 645px;
          height: 90px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(102, 102, 102, 1);
          font-size: 14px;
          letter-spacing: 1.75px;
          font-family: AlibabaPuHuiTiR;
          line-height: 30px;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .ptm-txt26 {
          width: 89px;
          height: 30px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(40, 40, 40, 1);
          font-size: 16px;
          letter-spacing: 2px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 30px;
          text-align: left;
          margin-top: 8px;
        }
        .ptm-infoBox4 {
          width: 645px;
          height: 90px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(102, 102, 102, 1);
          font-size: 14px;
          letter-spacing: 1.75px;
          font-family: AlibabaPuHuiTiR;
          line-height: 30px;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .ptm-layer8 {
          width: 311px;
          height: 75px;
          margin: 32px 0 0 64px;
          .ptm-word45 {
            width: 86px;
            height: 55px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(37, 184, 47, 1);
            font-size: 40px;
            letter-spacing: 2px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 55px;
            text-align: left;
          }
          .ptm-txt27 {
            width: 63px;
            height: 20px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(153, 153, 153, 1);
            font-size: 14px;
            letter-spacing: 1.75px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 20px;
            text-align: left;
            margin: 55px 0 0 -84px;
          }
          .ptm-wrap2 {
            width: 17px;
            height: 31px;
            background: url(../../assets/images/procedure/hook.png) -1px -2px
              no-repeat;
            margin: 27px 0 0 19px;
          }
          .ptm-info28 {
            width: 86px;
            height: 55px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(37, 184, 47, 1);
            font-size: 40px;
            letter-spacing: 2px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 55px;
            text-align: left;
            margin-left: 109px;
          }
          .ptm-txt28 {
            width: 63px;
            height: 20px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(153, 153, 153, 1);
            font-size: 14px;
            letter-spacing: 1.75px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 20px;
            text-align: left;
            margin: 55px 0 0 -84px;
          }
          .ptm-wrap3 {
            width: 17px;
            height: 31px;
            background: url(../../assets/images/procedure/hook.png) -1px -2px
              no-repeat;
            margin: 28px 0 0 19px;
          }
        }
        .ptm-layer9 {
          width: 300px;
          height: 60px;
          margin-top: 41px;
          .ptm-box18 {
            height: 60px;
            background-color: rgba(37, 184, 47, 1);
            box-shadow: 0px 12px 24px 0px rgba(37, 184, 47, 0.2);
            width: 180px;
            cursor: pointer;
            .ptm-word46 {
              width: 85px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiM;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 15px 0 0 47px;
            }
          }
          .ptm-icon20 {
            width: 42px;
            height: 40px;
            background: url(../../assets/images/procedure/grethan.png) no-repeat;
            margin-top: 10px;
          }
          .ptm-icon20:hover {
            background: url(../../assets/images/procedure/grethangreen.png) -24px -12px
              no-repeat;
          }
        }
      }
    }
    .ptm-word47 {
      width: 181px;
      height: 50px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(40, 40, 40, 1);
      font-size: 36px;
      letter-spacing: 0.7200000286102295px;
      font-family: AlibabaPuHuiTiM;
      white-space: nowrap;
      line-height: 50px;
      text-align: left;
      margin: 92px 0 0 869px;
    }
    .ptm-mod27 {
      width: 1236px;
      height: 264px;
      margin: 32px 0 0 342px;
      .ptm-pic15 {
        width: 340px;
        height: 264px;
      }
      .ptm-label28 {
        width: 46px;
        height: 25px;
        margin: 120px 0 0 29px;
      }
      .ptm-pic16 {
        width: 340px;
        height: 264px;
        margin-left: 33px;
      }
      .ptm-label29 {
        width: 46px;
        height: 25px;
        margin: 120px 0 0 35px;
      }
      .ptm-pic17 {
        width: 340px;
        height: 264px;
        margin-left: 27px;
      }
    }
    .ptm-mod28 {
      width: 969px;
      height: 25px;
      margin-left: 476px;
      .ptm-txt29 {
        width: 73px;
        height: 25px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 18px;
        letter-spacing: 0.36000001430511475px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 25px;
        text-align: left;
      }
      .ptm-info29 {
        width: 73px;
        height: 25px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 18px;
        letter-spacing: 0.36000001430511475px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 25px;
        text-align: left;
      }
      .ptm-info30 {
        width: 73px;
        height: 25px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 18px;
        letter-spacing: 0.36000001430511475px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 25px;
        text-align: left;
      }
    }
    .ptm-mod29 {
      width: 1141px;
      height: 48px;
      margin: 6px 0 0 390px;
      .ptm-paragraph6 {
        width: 245px;
        height: 48px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .ptm-paragraph7 {
        width: 245px;
        height: 48px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .ptm-paragraph8 {
        width: 245px;
        height: 48px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .ptm-mod30 {
      width: 1236px;
      height: 264px;
      margin: 40px 0 0 342px;
      .ptm-img8 {
        width: 340px;
        height: 264px;
      }
      .ptm-outer10 {
        width: 47px;
        height: 25px;
        background: url(../../assets/images/procedure/yellowleft.png) 0px -5px no-repeat;
        margin: 120px 0 0 29px;
      }
      .ptm-pic18 {
        width: 340px;
        height: 264px;
        margin-left: 32px;
      }
      .ptm-outer11 {
        width: 47px;
        height: 25px;
        background: url(../../assets/images/procedure/yellowleft.png) 0px -5px no-repeat;
        margin: 120px 0 0 35px;
      }
      .ptm-img9 {
        width: 340px;
        height: 264px;
        margin-left: 26px;
      }
    }
    .ptm-mod31 {
      width: 969px;
      height: 25px;
      margin-left: 476px;
      .ptm-info31 {
        width: 73px;
        height: 25px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 18px;
        letter-spacing: 0.36000001430511475px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 25px;
        text-align: left;
      }
      .ptm-info32 {
        width: 73px;
        height: 25px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 18px;
        letter-spacing: 0.36000001430511475px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 25px;
        text-align: left;
      }
      .ptm-word48 {
        width: 73px;
        height: 25px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 18px;
        letter-spacing: 0.36000001430511475px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 25px;
        text-align: left;
      }
    }
    .ptm-mod32 {
      width: 1141px;
      height: 48px;
      margin: 6px 0 0 390px;
      .ptm-infoBox5 {
        width: 245px;
        height: 48px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .ptm-paragraph9 {
        width: 245px;
        height: 48px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .ptm-infoBox6 {
        width: 245px;
        height: 48px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .ptm-mod33 {
      height: 527px;
      background-color: rgba(38, 38, 38, 1);
      margin-top: 120px;
      width: 1920px;
      .ptm-layer10 {
        width: 1520px;
        height: 362px;
        margin: 81px 0 0 201px;
        .ptm-block8 {
          width: 1015px;
          height: 36px;
          margin-left: 128px;
          .ptm-info33 {
            width: 38px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 18px;
            letter-spacing: 0.8571428656578064px;
            font-family: AlibabaPuHuiTiM;
            line-height: 36px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .ptm-word49 {
            width: 38px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 18px;
            letter-spacing: 0.8571428656578064px;
            font-family: AlibabaPuHuiTiM;
            line-height: 36px;
            text-align: left;
            margin-left: 156px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .ptm-word50 {
            width: 38px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 18px;
            letter-spacing: 0.8571428656578064px;
            font-family: AlibabaPuHuiTiM;
            line-height: 36px;
            text-align: left;
            margin-left: 116px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .ptm-word51 {
            width: 38px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 18px;
            letter-spacing: 0.8571428656578064px;
            font-family: AlibabaPuHuiTiM;
            line-height: 36px;
            text-align: left;
            margin-left: 116px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .ptm-info34 {
            width: 87px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 21px;
            letter-spacing: 1px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 36px;
            text-align: left;
            margin-left: 388px;
          }
        }
        .ptm-block9 {
          width: 1138px;
          height: 56px;
          margin: 20px 0 0 128px;
          .ptm-bd13 {
            width: 73px;
            height: 56px;
            .ptm-word52 {
              width: 73px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
            }
            .ptm-txt30 {
              width: 64px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
          }
          .ptm-bd14 {
            width: 67px;
            height: 56px;
            margin-left: 121px;
            .ptm-info35 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
            }
            .ptm-txt31 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
          }
          .ptm-word53 {
            width: 67px;
            height: 24px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 0.761904776096344px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 24px;
            text-align: left;
            margin-left: 87px;
          }
          .ptm-info36 {
            width: 67px;
            height: 24px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 0.761904776096344px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 24px;
            text-align: left;
            margin-left: 87px;
          }
          .ptm-txt32 {
            width: 210px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 0.761904776096344px;
            font-family: AlibabaPuHuiTiR;
            line-height: 36px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 16px 0 0 359px;
          }
        }
        .ptm-block10 {
          width: 1262px;
          height: 88px;
          margin: 8px 0 0 128px;
          .ptm-block11 {
            width: 77px;
            height: 88px;
            .ptm-word54 {
              width: 68px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
            }
            .ptm-info37 {
              width: 77px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
            .ptm-word55 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
          }
          .ptm-block12 {
            width: 67px;
            height: 56px;
            margin-left: 117px;
            .ptm-info38 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
            }
            .ptm-info39 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
          }
          .ptm-block13 {
            width: 334px;
            height: 84px;
            margin-left: 667px;
            .ptm-word56 {
              width: 334px;
              height: 36px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              line-height: 36px;
              text-align: left;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .ptm-word57 {
              width: 220px;
              height: 36px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              line-height: 36px;
              text-align: left;
              margin-top: 12px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        .ptm-word58 {
          width: 68px;
          height: 24px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 0.761904776096344px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 24px;
          text-align: left;
          margin: 8px 0 0 128px;
        }
        .ptm-txt33 {
          width: 632px;
          height: 24px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 0.8);
          font-size: 14px;
          letter-spacing: 1.75px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 24px;
          text-align: left;
          margin: 81px 0 0 443px;
        }
        // .ptm-block14 {
        //   width: 1520px;
        //   height: 1px;
        //   background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng36c95fcb9cd157277513064f87626c479109168afad811c1b82e7eec57e512e8)
        //     0px 0px no-repeat;
        //   margin-top: 16px;
        // }
      }
    }
  }
  .ptm-main16 {
    // z-index: 123;
    height: 396px;
    border: 1px solid rgba(245, 245, 245, 1);
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
    width: 488px;
    position: absolute;
    left: 716px;
    top: 2871px;
    .ptm-section11 {
      width: 116px;
      height: 185px;
      margin: 76px 0 0 184px;
      .ptm-word59 {
        width: 97px;
        height: 33px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(40, 40, 40, 1);
        font-size: 24px;
        letter-spacing: 0.47999998927116394px;
        font-family: AlibabaPuHuiTiM;
        white-space: nowrap;
        line-height: 33px;
        text-align: center;
        margin-left: 12px;
      }
      .ptm-bd15 {
        width: 116px;
        height: 28px;
        margin-top: 28px;
        .ptm-wrap5 {
          width: 21px;
          height: 21px;
          background: url(../../assets/images/procedure/tick.png) 0px 0px
            no-repeat;
          margin-top: 4px;
        }
        .ptm-info40 {
          width: 86px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(40, 40, 40, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 28px;
          text-align: left;
        }
      }
      .ptm-bd16 {
        width: 116px;
        height: 28px;
        margin-top: 20px;
        .ptm-main22 {
          width: 21px;
          height: 21px;
          background: url(../../assets/images/procedure/tick.png) 0px 0px
            no-repeat;
          margin-top: 4px;
        }
        .ptm-word60 {
          width: 86px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(40, 40, 40, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 28px;
          text-align: left;
        }
      }
      .ptm-bd17 {
        width: 116px;
        height: 28px;
        margin-top: 20px;
        .ptm-bd21 {
          width: 21px;
          height: 22px;
          background: url(../../assets/images/procedure/tick.png) 0px 0px
            no-repeat;
          margin-top: 4px;
        }
        .ptm-word61 {
          width: 86px;
          height: 28px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(40, 40, 40, 1);
          font-size: 16px;
          letter-spacing: 1.3333333730697632px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 28px;
          text-align: left;
        }
      }
    }
  }
  .ptm-main16:hover {
    background-color: rgba(37, 184, 47, 1);
    box-shadow: 0px 20px 40px 0px rgba(37, 184, 47, 0.24);
    .ptm-word59,
    .ptm-bd15 .ptm-info40,
    .ptm-bd16 .ptm-word60,
    .ptm-bd17 .ptm-word61 {
      color: rgba(255, 255, 255, 1);
    }
    .ptm-bd15 .ptm-wrap5,
    .ptm-bd16 .ptm-main22,
    .ptm-bd17 .ptm-bd21 {
      background: url(../../assets/images/procedure/whitetick.png) 0px 0px
        no-repeat;
    }
    .ptm-mod13 {
      width: 259px;
      height: 165px;
      background: url(../../assets/images/back/greencloth.png) 100% no-repeat;
      margin: 52px 0 0 114px;
      // margin-top: 120px;
      // margin-left: 172px;
    }
  }
}
</style>
<style src="../../assets/css/common.css" />